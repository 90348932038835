import React, { useState, useEffect } from 'react';
import "./assets/css/Experience.css";
import resume from "./assets/images/resume.png";
import aws from "./assets/images/aws.png";
import awsSa from "./assets/images/aws-sa-cert.png";
import cspa from "./assets/images/seal-cspo.png";
import cloudPrac from "./assets/images/AWS-CloudPractitioner-2020.png";
import evolution from "./assets/images/evolution.gif";
import Samples from "../../Samples/Samples";
import { Spring } from "react-spring/renderprops";
import {Helmet} from "react-helmet";

function Experience() {
   // scroll to top of component on load
   useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <Spring 
    from={{ opacity: 0 }} 
    to={{ opacity: 1 }}
    >
      {(props) => (<div className="content-component">

            <Helmet>
             <title>Thomas M. | Experience</title>
            </Helmet>
            <h1 className="component-header">EXPERIENCE</h1>
            <hr/>
            <img id="tech-header-img" src={evolution} alt="" className="img-fluid"/>
            <hr />
             {/* <p className="justify">
                My career path has afforded me a wide variety of experiences
                across multiple industries. From mastering soft skills as a
                spokesman at live events across the U.S. for Toyota Motor CO.,
                to sharpening hard skills as Full Stack Web Developer at Real Page Inc., I am
                able to draw from a deep well of experience to make a real
                contribution to any project or team.
             </p>
             <hr /> */}

             <h2 className="component-header">Highlights</h2>
             <br/>

             <a
                href="https://www.scrumalliance.org/community/profile/tmosley10"
                target="_blank"
              >
                <img src={cspa} className="cert-icon-exp hvr-grow" />
              </a>

             <a
                href="https://www.youracclaim.com/badges/8386087d-aa6f-466d-86fd-e2eeab3600cb"
                target="_blank"
              >
                <img src={awsSa} className="cert-icon-exp hvr-grow" />
              </a>

              <a
                href="https://www.youracclaim.com/badges/61f96fb0-f394-4b5c-9df0-5cd153899280"
                target="_blank"
              >
                <img src={cloudPrac} className="cert-icon-exp hvr-grow" />
              </a>
              {/* highlight wrapper */}
              <div className="exp-div-container">
              {/* 1 */}
              <div className="exp-div">
                  <p className="exp-header">Featured Roles</p>
                  <hr />
                  <ul>
                  <li className="exp-text">
                      <span>Product Manager</span>
                       <br /> @ Accenture
                    </li>
                    <br/>

                    <li className="exp-text">
                      <span>People Lead</span>
                       <br /> @ Accenture
                    </li>
                    <br />
                  <li className="exp-text">
                      <span>Advanced Application Engineering Specialist</span> <br /> @ Accenture
                    </li>
                    <br/>
                    <li className="exp-text">
                      <span>Full Stack Web Developer</span> <br /> @ RealPage
                      Inc.
                    </li>
                    <br />
                    <li className="exp-text">
                      <span>Network Switching Systems Admin (31F)</span>
                      <br /> @ U.S. Army
                    </li>
                  </ul>

                  <a href="https://drive.google.com/open?id=1R7c3vkZtAZZrDaKQG3fg2OE0m1H2kLRn" target="_blank" rel="noopener noreferrer" ><button  className="btn btn-primary hvr-grow">View Full Resume</button></a>
                </div>

               
               
              </div>
              <br/>
              
              <hr />

             
             

              {/* nothing below this point */}
              </div>

              



      
        
      )}
    </Spring>
  );
}

export default Experience;
