import React, { Component } from 'react';

import './assets/css/Rsidebar.css';
import cspo from './assets/images/seal-cspo.png';
import awsSa from './assets/images/aws-sa-cert.png';
import cloudPrac from './assets/images/AWS-CloudPractitioner-2020.png';
import cloudIcon from './assets/images/cloud.gif';




class RSidebar extends Component {
  
    render() {
        return (

            <div id="#"
                className=" col-md-2 col-lg-2 col-md-2 col-xs-2 rsidenav sidebar-color">
                <div id="Rsidebar-content">
                <img src={cloudIcon} className="cloud-icon"/>
                <hr />
                 {/* <br/> */}
                 <a href="https://www.scrumalliance.org/community/profile/tmosley10" target="_blank"><img src={cspo} className="cert-icon hvr-grow"/></a>

                  <a href="https://www.youracclaim.com/badges/8386087d-aa6f-466d-86fd-e2eeab3600cb" target="_blank"><img src={awsSa} className="cert-icon hvr-grow"/></a>

                <a href="https://www.youracclaim.com/badges/61f96fb0-f394-4b5c-9df0-5cd153899280" target="_blank" ><img src={cloudPrac} className="cert-icon hvr-grow"/></a> 
                  <hr />
                </div>

                {/* <div style={{width:'100%', height:0 , paddingBottom:"216%" , position: ""}}><iframe src="https://giphy.com/embed/qlOso7alCUTiAX7aG5" style={{  marginRight: "500px", width:"100%", height:"100%", position: "absolute"}} frameBorder="0" class="giphy-embed" allowFullScreen></iframe></div> */}
            </div>

        )
    }
}

export default RSidebar;