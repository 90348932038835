import React, { useState, useEffect } from 'react';
import './assets/css/CaseStudy.css';
import headshot from './assets/images/headshot.jpg';
import blob from './assets/images/blob.jpeg';
import { Spring } from 'react-spring/renderprops'
import {Helmet} from "react-helmet";




function CaseStudy() {


    
  // scroll to top of component on load
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (

    

    <Spring
     from={{ opacity: 0 }}
      to={{ opacity: 1 }}
    >
     {props => (
       <div style={props} className="content-component">
         <Helmet>
         <title>Thomas M. | About Me </title>
        </Helmet>
          <h1 className="component-header">Case Studies</h1>
          <br />
          <hr />
          <img id="about-img" src={blob} alt="thomas" className="component-header-img img-fluid" />
          <hr />
          <div className='cst-wrapper'>
          <p className="justify"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. In rhoncus ultricies erat, eget placerat magna placerat sit amet. Donec pulvinar tristique suscipit. Fusce rhoncus nibh vel diam pellentesque, vitae molestie arcu efficitur. Praesent facilisis nulla felis. Quisque vulputate enim magna, nec ornare nibh maximus ac. Phasellus vitae dapibus diam, et ultricies massa. Proin lacus velit, fringilla vel massa nec, condimentum fermentum leo. Cras nec vehicula massa. Vestibulum malesuada urna lacinia sem scelerisque consequat. Vestibulum bibendum diam a libero rhoncus, ac elementum sem luctus. Ut sed elit nisi. Quisque ipsum felis, condimentum eu lobortis vitae, elementum id enim. Pellentesque suscipit erat elit, at elementum dui elementum id.</p>

          <p className="justify"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. In rhoncus ultricies erat, eget placerat magna placerat sit amet. Donec pulvinar tristique suscipit. Fusce rhoncus nibh vel diam pellentesque, vitae molestie arcu efficitur. Praesent facilisis nulla felis. Quisque vulputate enim magna, nec ornare nibh maximus ac. Phasellus vitae dapibus diam, et ultricies massa. Proin lacus velit, fringilla vel massa nec, condimentum fermentum leo. Cras nec vehicula massa. Vestibulum malesuada urna lacinia sem scelerisque consequat. Vestibulum bibendum diam a libero rhoncus, ac elementum sem luctus. Ut sed elit nisi. Quisque ipsum felis, condimentum eu lobortis vitae, elementum id enim. Pellentesque suscipit erat elit, at elementum dui elementum id.</p>
          </div>
         

          
          <p className="justify">- Thomas</p>
          <hr/>

       </div>
     )}
    </Spring>



  );
}

export default CaseStudy;  