import React, { useState, useEffect } from 'react';
import './assets/css/Contact.css';
import linkedin from './assets/images/linkedin.png'
import email from './assets/images/email.png'
import connect from './assets/images/connect-2.gif'
import { Spring } from 'react-spring/renderprops'
import {Helmet} from "react-helmet";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import EmailImg from './assets/images/email.jpg';
import linkedIn from './assets/images/linkedIn2.png';






function Contact() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (

    <Spring
      from={{ opacity: 0 }}
      to={{ opacity: 1 }}
    >
      {props => (
        <div className="content-component">

        <Helmet>
         <title>Thomas M. | Contact</title>
        </Helmet>

        <h1 className="component-header">Contact</h1>
            <hr />
            <img className="contact-image" src={"https://media.giphy.com/media/R4H1oAuFVnEwdsXVoR/giphy.gif"} alt="" />
            <br />
            <hr />
            <br />
          
            <Card className={"card"} style={{ float: 'none' ,borderRadius: '10px'}}>
              <Card.Img variant="top" src={EmailImg} style={{borderRadius:'10px'}} />
              <Card.Body>
                <Card.Title>TMosley.PM@gmail.com</Card.Title>
            
               <a href="mailto: tmosley.pm@gmail.com ?subject= INQUIRY FROM TM3.DEV: ** Type your subject here. ** &body=" target="_blank" rel="noopener noreferrer" className="contact-link">
                <Button variant="primary">Send an email</Button>
               </a>
              </Card.Body>
            </Card> 

            <Card className={"cards"} style={{ float: 'none', borderRadius: '10px'}}>
              <Card.Img variant="top" src={linkedIn} style={{borderRadius:'10px'}} />
              <Card.Body>
                <Card.Title>/tmosley03</Card.Title>

                 <a href="https://www.linkedin.com/in/tmosley03/" target="_blank" rel="noopener noreferrer" className="contact-link">
                  <Button variant="primary">Connect</Button>
                 </a>
              </Card.Body>
            </Card>
           

            {/* <div className="contact-icon-div">
                <a href="mailto: tlm3dev@gmail.com ?subject= INQUIRY FROM TM3.DEV: ** Type your subject here. ** &body=" target="_blank" rel="noopener noreferrer" className="contact-link">
                  <img src={email} alt="email" className="contact-icon" />                 
                  <p className="contact-icon-text">tlm3dev@gmail.com</p>
                </a>
            </div> */}


            {/* <div className="contact-icon-div">
              <a href="https://www.linkedin.com/in/tmosley03/" target="_blank" rel="noopener noreferrer" className="contact-link">
                <img src={linkedin} alt="linkedin" className="contact-icon" />               
                <p className="contact-icon-text">/in/tmosley03/</p>
              </a>
            </div> */}

            <br />
            <hr />
            {/* <p className="justify">Are you ready to take advantage of the security, reliability, "pay-as-you-go" pricing, and global infrastructure that the cloud can provide?  Maybe you're on the fence and want to get a little more information about what's possible.  Either way, I would love to hear from you and see how I can help you reach your goals in the cloud. </p>

            <hr /> */}
            


          
        



        </div>
      )}
    </Spring>



  );
}

export default Contact;  