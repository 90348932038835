import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './assets/css/NavMenu.css';
import tm3 from './assets/images/tm3-logo.png';
import cloudIcon from './assets/images/cloud.gif';

const NavMenu = (props) => {

    
    const [active, setActive] = useState(false)


  
        return(     
            <div className="nav-wrapper" onClick ={ props.click }>
               
                <img id="nav-menu-logo" src={cloudIcon} alt="" className="img-fluid" />
                <hr/>
                <ul>
                    <li><Link  to="/about" className="nav-text drawer-link hvr-grow  text-uppercase" onClick={ () => setActive(!active) }>
                       About Me               
                    </Link></li>

                    <li> <Link to="/experience" className="nav-text drawer-link hvr-grow  text-uppercase" onClick={ () => setActive(!active) }>
                        Experience
                    </Link></li>

                    <li> <Link  to="/site-stack" className="nav-text drawer-link hvr-grow  text-uppercase" onClick={ () => setActive(!active) }>
                       Site Stack                
                    </Link></li>
{/* 
                    <li> <Link to="/case-study" className="nav-text drawer-link hvr-grow  text-uppercase" onClick={ () => setActive(!active) }>
                        Case Studies
                    </Link></li> */}

                    <li> <Link to="/tools" className="nav-text drawer-link hvr-grow  text-uppercase" onClick={ () => setActive(!active) }>
                        Tools
                    </Link></li>

                    <li> <Link to="contact" className="nav-text drawer-link hvr-grow  text-uppercase" onClick={ () => setActive(!active) }>
                        Contact
                    </Link></li>
                </ul>
                <hr />
               

            </div>
        )
    
}

export default NavMenu;