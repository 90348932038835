// dependencies
import { Route, Switch, HashRouter } from 'react-router-dom'; 
import {browserHistory } from 'react-router';
import React, { Component } from 'react';
import { Spring } from 'react-spring/renderprops'
import {Helmet} from "react-helmet";
import ReactGA from 'react-ga';
import './App.css';
// components
import Header from './assets/components/Header/Header';
import LSidebar from './assets/components/LSidebar/LSidebar';

import Drawer from './assets/components/Drawer/Drawer';
import Backdrop from './assets/components/Backdrop/Backdrop';
import RSidebar from './assets/components/RSidebar/RSidebar';
import Footer from './assets/components/Footer/Footer';

// pages
import SiteStack from './assets/components/Pages/Site-Stack/SiteStack';
import About from './assets/components/Pages/About/About';
import Contact from './assets/components/Pages/Contact/Contact';
import Experience from './assets/components/Pages/Experience/Experience'; 
import Technology from './assets/components/Pages/Technology/Technology';
import CaseStudy from './assets/components/Pages/Case-Study/CaseStudy';

ReactGA.initialize('G-EQ5N7GCVJB');
ReactGA.pageview(window.location.pathname + window.location.search);


class App extends Component  {

  state = {
    drawerIsOpen: false
  }

  drawerToggler = () => {
    this.setState((prevState) =>{
      return {drawerIsOpen: !prevState.drawerIsOpen};      
    });

  };

  backdropClickHandler = () => {
    this.setState({drawerIsOpen: false});
    
  }

  render(){
    let backdrop; 

    if(this.state.drawerIsOpen){
       backdrop = <Backdrop click= {this.backdropClickHandler} />;
      
     }

  return (
    
    <Spring
      from={{ opacity: 0 }}
      to={{ opacity:1}}
    >
  
    {props =>(
      <div style={props}>
      <HashRouter history= {browserHistory} >
      <div className="App"> 
      <Helmet>
      <title>Product Manager</title>
     
      </Helmet>     
    
      {backdrop}  
      
        <Header drawerClickHandler = {this.drawerToggler} />
        <LSidebar />
        <Drawer show = { this.state.drawerIsOpen }  click = {this.backdropClickHandler} />
        <div className="container">    
          <div className="row">
            <Switch>
              <Route path="/" exact component={About} />
              <Route path="/about" component={About} />
              <Route path="/site-stack" component={SiteStack} />
              <Route path="/case-study" component={CaseStudy} />
              <Route path="/tools" component={Technology} />
              <Route path="/experience" component={Experience} />
              <Route path="/contact" component={Contact} />
            </Switch>

          </div>
        </div>
        <RSidebar />
        <Footer />
      </div>
      </HashRouter>
      </div>
    )}
    </Spring>
  );
}
}
export default App 

