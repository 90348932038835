import React from 'react';
import NavMenu from '../NavMenu/NavMenu';
import '../../../hover.css';
import './assets/css/LSidebar.css';


const LSidebar = () => {
       
        return (

            <div id="sidebar-nav" className="col-lg-2 col-md-2 col-xs-2 sidenav sidebar-color">
                <div id="sidebar-content">
                    
                  <NavMenu />

                </div>
            
            </div>
        )
    
}

export default LSidebar;