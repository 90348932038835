import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Spring } from 'react-spring/renderprops';
import {Helmet} from "react-helmet";
import './assets/css/AboutSite.css';


// ======= new ==================================
import react from './assets/images/site-ready/react.png'
import reactRouter from './assets/images/site-ready/react-router-icon.png'
import route53 from './assets/images/site-ready/route53-icon.png'
import css3 from './assets/images/site-ready/css-icon.png'
import html from './assets/images/site-ready/html-icon.png'
import s3 from './assets/images/site-ready/s3-icon.png'
import certman from './assets/images/site-ready/certman-icon.png'
import cloudfront from './assets/images/site-ready/cloudfront-icon.png'
import iam from './assets/images/site-ready/iam-icon.png'
import codecommit from './assets/images/site-ready/codecommit.png'
import bootstrap from './assets/images/bootstrap4.png'
import cogs from './assets/images/cogs.gif'
import diagram from './assets/images/diagram.svg'
// ================================================


function SiteStack() {
  
  // scroll to top of component on load
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (

    <Spring
      from={{ opacity: 0 }}
      to={{ opacity: 1 }}
    >
      {props => (
        <div style={props} className='content-component' id="#">
        <Helmet>
         <title>Thomas M. | Product Manager</title>
        </Helmet>
         
                <h1 className="component-header">What's Under The Hood?</h1>
                <br/>
                <hr/>
                <img className="component-header-img" id="about-site-img" src={cogs}/>
                <hr/>
                <div className="ab-text-wrapper">
                <p className="justify">This is a static website written primarily in React JS and hosted on the Amazon Web Services platform.  Listed below are the tools and a brief description on how they were used to bring this site to your device.</p> 
                </div>
                <hr/>
                <br/>
                <br/>

                 {/* ==================== */}
                 <h2 className="component-header">- BACK END -</h2>
                
                <br/>
                <br/>

                <img className="diagram" id="#" src={diagram} />

                <br/>
                <br/>

                <div className="accordion" id="accordionExample">
                
                  {/* 01 */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <img  src={s3} alt="" className="img-fluid site-tool-icon " />
                      <p className="site-tool-text"> AWS S3 Bucket</p>
                      </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                      
                        S3 is a perfect storage option for hosting a static website such as this one.  Object Versioning, 99.999999999% durability of objects, and loss of 0.000000001% of objects ensures that the site files will always be there for the users to access.
                      </div>
                    </div>
                  </div>

                  {/* 02 */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <img  src={route53} alt="" className="img-fluid site-tool-icon " />
                        <p className="site-tool-text">AWS  Route 53</p>
                      </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                      
                      Amazon Route 53 is a highly available and scalable cloud Domain Name System (DNS) web service. In the case of this site, it was used to point the domain name to the AWS S3 bucket which hosts the code for the site.
                      </div>
                    </div>
                  </div>
                  {/* 03 */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <img  src={iam} alt="" className="img-fluid site-tool-icon " />
                      <p className="site-tool-text">AWS  IAM</p>
                      </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                     
                      AWS Identity and Access Management (IAM) enables you to manage access to AWS services and resources securely.
                      </div>
                    </div>
                  </div>
                  {/* 04 */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      <img  src={codecommit} alt="" className="img-fluid site-tool-icon " /> 
                      <p className="site-tool-text">AWS  CodeCommit</p>
                      </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                      AWS CodeCommit is a fully-managed source control service that hosts secure Git-based repositories. I chose this service because it is in the AWS eco system and that means fewer logins and seemless intergration.
                      </div>
                    </div>
                  </div>
                  {/* 05 */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                      <img  src={certman} alt="" className="img-fluid site-tool-icon " /> 
                      <p className="site-tool-text">AWS Cert. Manager</p>
                      </button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                      AWS Certificate Manager is a service that lets you easily provision, manage, and deploy public and private Secure Sockets Layer/Transport Layer Security (SSL/TLS) certificates.  This is critical not only for security, but SEO rankings as well.
                      </div>
                    </div>
                  </div>
                  {/* 06 */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseThree">
                      <img  src={cloudfront} alt="" className="img-fluid site-tool-icon " />
                      <p className="site-tool-text">AWS  CloudFront</p>
                      </button>
                    </h2>
                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                      Amazon CloudFront is a fast content delivery network (CDN) service that securely delivers data, videos, applications, and APIs to customers globally with low latency, high transfer speeds.
                      </div>
                    </div>
                  </div>
                </div>
                {/* ==================== */}
                <br />
                <br />
                <h2 className="component-header">- FRONT END -</h2>
                <br/>  
                <br />
                <div className="accordion" id="accordionExample2">
                
                  {/* 07 */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSeven">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                      <img  src={react} alt="" className="img-fluid site-tool-icon " />
                      <p className="site-tool-text">React JS</p>
                      </button>
                    </h2>
                    <div id="collapseSeven" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample2">
                      <div className="accordion-body">
                      
                      React JS is a popular component based Javascript framwork that allows for quick development.  There are various ways to implement React (styled components, class based components, fucntional components). In the case of this site, I opted for functional components simply due to the overall size of the project/site.  If this were a larger site, or an application, I feel like styled components would be more appropriate.
                      </div>
                    </div>
                  </div>

                  {/* 08 */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEight">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                      <img  src={reactRouter} alt="" className="img-fluid site-tool-icon " />
                       <p className="site-tool-text">React Router</p>
                      </button>
                    </h2>
                    <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample2">
                      <div className="accordion-body">
                      
                      React Router was used for internal navigation.
                      </div>
                    </div>
                  </div>
                  {/* 09 */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingNine">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                      <img  src={bootstrap} alt="" className="img-fluid site-tool-icon " />
                     <p className="site-tool-text">Bootstrap</p>
                      </button>
                    </h2>
                    <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample2">
                      <div className="accordion-body">
                    
                      Bootstrap is the most popular CSS Framework for developing responsive and mobile-first websites.
                      </div>
                    </div>
                  </div>
                  {/* 10 */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTen">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                      <img  src={css3} alt="" className="img-fluid site-tool-icon " /> 
                      <p className="site-tool-text">CSS3</p>
                      </button>
                    </h2>
                    <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample2">
                      <div className="accordion-body">
                      Cascading Styl Sheets, or CSS, was used to style the elements of this site.
                      </div>
                    </div>
                  </div>
                  {/* 11 */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEleven">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                      <img  src={html} alt="" className="img-fluid site-tool-icon " /> 
                     <p className="site-tool-text">HTML5</p>
                      </button>
                    </h2>
                    <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample2">
                      <div className="accordion-body">
                      Hypertext Markup Language (HTML) is the standard markup language for documents designed to be displayed in a web browser. It can be assisted by technologies such as Cascading Style Sheets (CSS) and scripting languages such as JavaScript.  However, in the case of this site, since it is written in React JS, JSX was used in lieu of pure HTML.
                      </div>
                    </div>
                  </div>

                </div>


                {/* ===================== */}
              
               
                          

                
                <br/>
                <hr/>
               <br/>
               
            
          
        </div>
      )}
    </Spring>



  );
}

export default SiteStack;  