import React, { useState, useEffect } from 'react';
import './assets/css/Technology.css';
import { Spring } from 'react-spring/renderprops'
import LazyLoad from 'react-lazy-load';
import {Helmet} from "react-helmet";
// import technology from './assets/images/technology.jpg'
// =========================================
import ajax from './assets/images/tech-icons/ajax.png'
import bootstrap from './assets/images/tech-icons/bootstrap4.png'
import html from './assets/images/tech-icons/html-css-js.png'
// import express from './assets/images/tech-icons/express.png'
import firebase from './assets/images/tech-icons/firebase.png'
// =========================================
import git from './assets/images/tech-icons/git.png'
import wix from './assets/images/tech-icons/wix.png'
import vm from './assets/images/tech-icons/vm.png'
import jira from './assets/images/tech-icons/jira.png'
import jquery from './assets/images/tech-icons/jquery.png'
// =========================================
import laravel from './assets/images/tech-icons/laravel.png'
import materialize from './assets/images/tech-icons/materialize.png'
import mongo from './assets/images/tech-icons/mongo.png'
import node from './assets/images/tech-icons/node.png'
import npm from './assets/images/tech-icons/npm.png'

// ======= new ==================================
import python from './assets/images/site-ready/python-icon.png'
import outlook from './assets/images/site-ready/outlook-icon.png'
import route53 from './assets/images/site-ready/route53-icon.png'
import vpc from './assets/images/site-ready/vpc-icon.png'
import s3 from './assets/images/site-ready/s3-icon.png'
import ec2 from './assets/images/site-ready/ec2-icon.png'
import certman from './assets/images/site-ready/certman-icon.png'
import cloudfront from './assets/images/site-ready/cloudfront-icon.png'
import iam from './assets/images/site-ready/iam-icon.png'
import codecommit from './assets/images/site-ready/codecommit.png'
import squarespace from './assets/images/site-ready/square-space.png'
// =========================================
import parcel from './assets/images/tech-icons/parcel.png'
import photoshop from './assets/images/tech-icons/photoshop.png'
import php from './assets/images/tech-icons/php.png'
import premiere from './assets/images/tech-icons/premiere.png'
import react from './assets/images/tech-icons/react.png'
import apache from './assets/images/tech-icons/apache.png'
import giphy from './assets/images/giphy.gif'

// =========================================
// import restapi from './assets/images/tech-icons/restapi.png'
import salesforce from './assets/images/tech-icons/salesforce.png'
import sql from './assets/images/tech-icons/mysql.png'
import ubuntu from './assets/images/tech-icons/ubuntu.png'
import vue from './assets/images/tech-icons/vue.png'
import wordpress from './assets/images/tech-icons/wordpress.png'
import yarn from './assets/images/tech-icons/yarn.png'
import webpack from './assets/images/tech-icons/webpack.png'
import mac from './assets/images/tech-icons/mac.png'
import windows from './assets/images/tech-icons/windows.png'
import vim from './assets/images/tech-icons/vim.png'
import mamp from './assets/images/tech-icons/mamp.png'
import { Link } from 'react-router-dom';
//add apache, mamp, ftp
// =========================================



function Technology() {
  
   // scroll to top of component on load
   useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (

<Spring
      from={{ opacity: 0 }}
      to={{ opacity:1}}
    >
   {props =>(
     <div style={props} className="content-component">
            <Helmet>
             <title>Thomas M. | Tech</title>
            </Helmet>
        <h1 className="component-header">TECHNOLOGY</h1>
        <hr />
        <img id="tech-img" src={"https://media.giphy.com/media/YULPJoecGetvtOm1H0/giphy.gif"} alt="" className="component-header-img img-fluid" />
        {/* <hr /> */}


        <div className="ab-text-wrapper">
        <p className="justify">While working as a developer, I have used many different tools and technologies on a wide array of projects, too many keep up with in fact. So, rather than try to put together a list of all the different tech I've used over my career, the icons shown below represent a snapshot of some of the tools and technology that I most commonly work with.</p>
        </div>
        <hr />
      <h2 className="component-header">Front End</h2>
      <br/>
      <img  src={html} alt="" className="img-fluid lang-icon" />
      <img  src={react} alt="" className="img-fluid lang-icon" />
      <img  src={vue} alt="" className="img-fluid lang-icon" />
      <img  src={jquery} alt="" className="img-fluid lang-icon" />
      <img  src={ajax} alt="" className="img-fluid lang-icon" />
      <img  src={bootstrap} alt="" className="img-fluid lang-icon" />
      <img  src={materialize} alt="" className="img-fluid lang-icon" />

      <br/>
      <br/>
      <h2 className="component-header">Back End</h2>
      <br/>
      <img  src={ec2} alt="" className="img-fluid lang-icon" />
      <img  src={vpc} alt="" className="img-fluid lang-icon" />
      <img  src={s3} alt="" className="img-fluid lang-icon" />
      <img  src={cloudfront} alt="" className="img-fluid lang-icon" />
      <img  src={certman} alt="" className="img-fluid lang-icon" />
      <img  src={route53} alt="" className="img-fluid lang-icon" />
      {/* ^^^^ cloud ^^^^ */}
      <img  src={python} alt="" className="img-fluid lang-icon" />
      <img  src={sql} alt="" className="img-fluid lang-icon" />
      <img  src={php} alt="" className="img-fluid lang-icon" />
      <img  src={apache} alt="" className="img-fluid lang-icon" />
      <img  src={laravel} alt="" className="img-fluid lang-icon" />
      <img  src={node} alt="" className="img-fluid lang-icon" />
      <img  src={mongo} alt="" className="img-fluid lang-icon" />
      <img  src={vim} alt="" className="img-fluid lang-icon" />
      <img  src={firebase} alt="" className="img-fluid lang-icon" />
      <img  src={mamp} alt="" className="img-fluid lang-icon" />
      <br/>
      <br/>
      <h2 className="component-header">Build Tools</h2>
      <br/>
      <img  src={webpack} alt="" className="img-fluid lang-icon" />
      <img  src={parcel} alt="" className="img-fluid lang-icon" />
      <img  src={yarn} alt="" className="img-fluid lang-icon" />
      <img  src={npm} alt="" className="img-fluid lang-icon" />
      <br/>
      <br/>
      <h2 className="component-header">Operating Systems</h2>
      <br/>
      <img  src={ubuntu} alt="" className="img-fluid lang-icon" />
      <img  src={mac} alt="" className="img-fluid lang-icon" />
      <img  src={windows} alt="" className="img-fluid lang-icon" />
      <br/>
      <br/>
      <h2 className="component-header">WYSIWYG Platforms</h2>
      <br/>
      <img  src={wordpress} alt="" className="img-fluid lang-icon" />
      <img  src={wix} alt="" className="img-fluid lang-icon" />
      <br/>
      <br/>
      <h2 className="component-header">Other Tech</h2>
      <br/>
      <img  src={iam} alt="" className="img-fluid lang-icon" />
      <img  src={outlook} alt="" className="img-fluid lang-icon" />
      <img  src={jira} alt="" className="img-fluid lang-icon" />
      <img  src={codecommit} alt="" className="img-fluid lang-icon" />
      <img  src={git} alt="" className="img-fluid lang-icon" />
      <img  src={vm} alt="" className="img-fluid lang-icon" />
      <img  src={salesforce} alt="" className="img-fluid lang-icon" />
      <img  src={photoshop} alt="" className="img-fluid lang-icon" />
      <img  src={premiere} alt="" className="img-fluid lang-icon" />
      <hr/>

     </div>
   )}
    </Spring>


    

  );
}

export default Technology;  