import React from 'react';
import './assets/css/Footer.css';




function Footer() {
  return (
    <div id="footer" className="row navbar-nav fixed-bottom ">
        <div className="container">
          <p className="text-center footer-txt">tm3.dev ©2022 </p>
        </div>     
    </div>

  );
}

export default Footer;  
