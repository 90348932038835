import React, { useState, useEffect } from 'react';
import './assets/css/About.css';
import headshot from './assets/images/headshot.jpg';
import hs from './assets/images/hs.jpg';
import blob from './assets/images/blob.jpeg';
import { Spring } from 'react-spring/renderprops'
import { Helmet } from "react-helmet";




function About() {



  // scroll to top of component on load
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (



    <Spring
      from={{ opacity: 0 }}
      to={{ opacity: 1 }}
    >
      {props => (
        <div style={props} className="content-component">
          <Helmet>
            <title>Thomas M. | About Me </title>
          </Helmet>
          <h1 className="component-header">About Me</h1>
          <br />
          <hr />
          <img id="about-img" src={hs} alt="thomas"  />
          {/* <div ><iframe src="https://giphy.com/embed/12jzJ3iOw9rA9a"  style={{position:"absolute", frameBorder: 0, width: 100 , height: 100}} className="giphy-embed" allowFullScreen></iframe></div> */}
          <hr />

          <div className="ab-text-wrapper">
            <p className="ab-title">Where are you based out of?</p>
            <p className="center">- Dallas-Fort Worth, TX U.S.A. -</p>

            <p className="ab-title">How were you introduced to Product Management?</p>
            <p className="justify">I had been working as a Software Engineer for a few years when I was staffed on a project to build an application for a U.S. based theme park company.  It was my first time working as a Junior Anchor, which meant that I was going to be a little further upstream in the development lifecycle and I had the opportunity to work directly with the lead Product Manager.  Up until that point, I had only worked on user stories after they were already written and refined. Now, I was participating in discovery and framing meetings, refining stories, contributing to architectural discussions, and more.  Since the PM didn't come from a technical background, I took the opportunity to serve as a liaison between them and certain stakeholders.   From that project onward, I was hooked.  Although I do enjoy writing code and building new things, Product Management has opened up an avenue for me to have a broader view of a products lifecycle and be more dynamic in my approach to problem solving and, I have to say, it has been the most fulfilling part of my career yet.</p>

            <p className="ab-title">Hobbies & Interests</p>
            <p className="center">
              <ul>
                <li>- Day/Swing Trading Options -</li>
                <li>- Spending time with my dog -</li>
                <li>- Traveling -</li>
              </ul>
            </p>

            <p className="ab-title">Skills & Methodologies</p>
            <p className="center">
              <ul>
                <li>- Product Management -</li>
                <li>- Web Development -</li>
                <li>- Responsive Design -</li>
                <li>- Resource Management -</li>
                <li>- Test Driven Development -</li>
                <li>- Agile SDLC -</li>
                <li>- SCRUM -</li>
                <li>- Kanban -</li>
                <li>- Lean XP -</li>
                <li>- Pair Programming -</li>
                <li>- Microsoft Office -</li>
                <li>- Jira -</li>

              </ul>
            </p>

            <p className="ab-title">Remote, On Site, or Hybrid?</p>
            <p className="center">
              My first choice would be to work remotely, but for the right situation I would be willing to consider an on site or hybrid position. 
            </p>
          </div>
          <hr />

        </div>
      )}
    </Spring>



  );
}

export default About;  