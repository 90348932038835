import React from "react";
import NavMenu from '../NavMenu/NavMenu';
import awsSa from "./assets/images/aws-sa-cert.png";
import cspa from "./assets/images/seal-cspo.png";
import cloudPrac from "./assets/images/AWS-CloudPractitioner-2020.png";
import "./assets/css/Drawer.css";

const Drawer = (props) =>{
  
let drawerClasses = 'drawer';


if (props.show ){
  drawerClasses = 'open-drawer drawer';
  }


    return (
      <nav  className = {drawerClasses}>
        <NavMenu click = {props.click} />
        <div>
        <a href="https://www.scrumalliance.org/community/profile/tmosley10"
                target="_blank" >
                <img src={cspa} className="cert-icon-drawer hvr-grow" />
              </a>

        <a href="https://www.youracclaim.com/badges/8386087d-aa6f-466d-86fd-e2eeab3600cb"
                target="_blank">
                <img src={awsSa} className="cert-icon-drawer hvr-grow " />
              </a>

              <a href="https://www.youracclaim.com/badges/61f96fb0-f394-4b5c-9df0-5cd153899280"
                target="_blank">
                <img src={cloudPrac} className="cert-icon-drawer hvr-grow" />
              </a>
              </div>
      </nav>
     );

}

export default Drawer