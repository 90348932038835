import React from 'react';
import './assets/css/header.css';
import ToggleBtn from '../ToggleBtn/ToggleBtn';



const header =  (props) => {
    return(


       <div>
        <nav id= "header" className= "navbar fixed-top">
          <div className="name-wrapper">
             <p  id="" className="header-text d-inline-block align-top">Thomas Mosley | <span className="subtitle d-inline-block align-top">Product Manager</span></p>
          </div>
          <ToggleBtn click ={ props.drawerClickHandler }/>
        </nav>
       </div>
    )
}

export default header;